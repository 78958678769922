import { lazy } from "react";
const AdminOrder = lazy(() =>
  import("../Admin/Components/components/AdminOrder")
);
const AdminManage = lazy(() =>
  import("../Admin/Components/components/AdminManage")
);
const AdminContact = lazy(() =>
  import("../Admin/Components/components/AdminContact")
);
const AdminRoutes = [
  { path: "contact", Component: AdminContact },
  { path: "manage", Component: AdminManage },
  { path: "order", Component: AdminOrder },
];

export default AdminRoutes;
