import React, { useState } from "react";

function ScrollToTop() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling effect
    });
  };

  const handleScrollVisibility = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  // Attach scroll event listener to window
  window.addEventListener("scroll", handleScrollVisibility);

  return (
    <button
      className={`${
        showScrollButton ? "opacity-100" : "opacity-0"
      } transition-opacity duration-500 bg-green-500 hover:bg-green-600 text-white rounded-full fixed top-[88vh] z-50 left-5 lg:w-16 lg:h-16 md:w-12 md:h-12 w-8 h-8 animate-bounce`}
      onClick={handleScroll}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="lg:w-14 md:w-10 w-6 mx-auto"
      >
        <path
          fill="currentColor"
          d="M8 256C8 119 119 8 256 8s248 111 248 248s-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"
        />
      </svg>
    </button>
  );
}

export default ScrollToTop;
