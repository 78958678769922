import { useEffect, useRef, useState } from "react";
import customizeShopperBD from "../Assets/images/customize-shopper-bd.webp";
import { Link } from "react-router-dom";

const Navbar = () => {
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-11/12 mx-auto !z-50 pt-[5vh]">
      <ul className="font-medium flex md:justify-start justify-between items-center relative">
        {/* logo */}
        <div className="w-32">
          <Link to="/">
            <img src={customizeShopperBD} alt="customize-super-bd-logo" />
          </Link>
        </div>

        {/* For desk and tab */}
        <div className="md:flex md:pl-20 items-center justify-center hidden !z-50">
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[8rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/">Home</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[9rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/products">Products</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[7rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/faq">FAQ</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[8.5rem] h-[6rem]" />
            <div className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
        {/* for mob */}
        <div className="md:hidden !z-50">
          <button onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z"
                />
              </svg>
            )}
          </button>
        </div>
        <div
          ref={menuRef}
          className={`${
            isOpen
              ? "opacity-100 duration-300 ease-in transition-all transform block"
              : "opacity-0 duration-300 ease-in transition-all transform hidden"
          } inline-block md:pl-20 items-center justify-center md:hidden absolute right-0 top-20 backdrop-blur-xl bg-gray-800/50 rounded-md text-white !z-50`}
        >
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[8rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/">Home</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[9rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/products">Products</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[7rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/faq">FAQ</Link>
            </p>
          </div>
          <div className="relative group">
            <div className="group-hover:opacity-100 opacity-0 transition-all duration-500 ease-in nav_bg w-[8.5rem] h-[6rem]" />
            <p className="absolute left-9 top-9 group-hover:text-white hover:text-white duration-300 ease-in group-hover:font-bold drop-shadow-xl">
              <Link to="/contact">Contact</Link>
            </p>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default Navbar;
