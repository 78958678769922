import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// install react router dom
import { BrowserRouter } from "react-router-dom";
// install meterial tailwind
import { ThemeProvider } from "@material-tailwind/react";
// import dotenv from "dotenv";
// dotenv.config();
ReactDOM.createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);
