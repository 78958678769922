import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./Layouts/Footer";
import Navbar from "./Layouts/Navbar";
import PublicRoutes from "./Routes/PublicRoutes";
import { Route, Routes, useLocation } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import Loader from "./Utils/Loader";
import AdminRoutes from "./Routes/AdminRoute";
import ScrollToTop from "./Utils/ScrollToTop";
const Login = lazy(() => import("./Admin/Auth/Login"));
const Home = lazy(() => import("./Admin/Pages/Home"));
const PageNotFound = lazy(() => import("./Utils/PageNotFound"));
const AdminDashboard = lazy(() => import("./Admin/Components/AdminDashboard"));
// Custom ScrollTop component
function ScrollTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const userInfo = localStorage.getItem("userInfo");
  const user = userInfo ? JSON.parse(userInfo) : null; // Parse the stored JSON
  const userPassword = user ? user.password : null;

  useEffect(() => {
    AOS.init({
      delay: 100,
      easing: "ease",
      duration: 1000,
      useClassNames: true,
      initClassName: false,
      animatedClassName: "animated",
    });
  }, []);
  const env_pass = process.env.REACT_APP_ADMIN_PASS;
  return (
    <div className="!overflow-x-hidden">
      <Suspense fallback={<Loader />}>
        <Navbar />
        <ScrollTop />
        <ScrollToTop />
        <Routes>
          {PublicRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
          <Route path="/admin" element={<Login />} />
          <Route
            path={"/dashboard"}
            element={
              userPassword === env_pass ? <AdminDashboard /> : <PageNotFound />
            }
          >
            <Route
              index
              element={userPassword === env_pass ? <Home /> : <PageNotFound />}
            ></Route>
            {AdminRoutes.map(({ path, Component }, index) => (
              <Route
                key={index}
                path={path}
                element={
                  userPassword === env_pass ? <Component /> : <PageNotFound />
                }
              />
            ))}
          </Route>
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
