import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const SITEMAP = [
  {
    title: "Company",
    links: [
      { name: "Trams&Condition", url: "/trams-condition" },
      { name: "Privacy Policy", url: "/privacy-policy" },
      { name: "Replacement Policy", url: "/replacement-policy" },
      { name: "Shipping & Delivery", url: "/shipping-delivery" },
      { name: "Refund and Returns Policy", url: "/refund-return" },
    ],
  },
  {
    title: "Help Center",
    links: [
      {
        name: "Whats app",
        url: "https://wa.me/+8801848189974/?text=Hello Customize Shopper BD!",
      },
      { name: "Facebook", url: "https://www.facebook.com/customize.shopper" },
      { name: "Contact Us", url: "/contact" },
      { name: "FAQ", url: "/faq" },
    ],
  },
  {
    title: "Address",
    links: [
      {
        name: "Khaer Hussain  chairman Bari   komira Sitakundah Chittagong ",
        url: "https://www.google.com/maps/place/Chairman+Bari,+Dhaka+1212/@23.7877515,90.3921752,16z/data=!3m1!4b1!4m10!1m2!2m1!1sKhaer+Hussain++chairman+Bari+++komira+Sitakundah%C2%A0Chittagong!3m6!1s0x3755c712c919cd45:0x58c10ab1f9b6cf99!8m2!3d23.7873041!4d90.4025051!15sCjxLaGFlciBIdXNzYWluICBjaGFpcm1hbiBCYXJpICAga29taXJhIFNpdGFrdW5kYWjCoENoaXR0YWdvbmeSAQxuZWlnaGJvcmhvb2TgAQA!16s%2Fg%2F1vzg0lsz?entry=ttu",
      },
      {
        name: "01631641447",
        url: "https://wa.me/+8801631641447/?text=Hello Customize Shopper BD!",
      },
      {
        name: "01848189974",
        url: "https://wa.me/+8801848189974/?text=Hello Customize Shopper BD!",
      },
    ],
  },
  {
    title: "Our Products",
    links: [
      {
        name: "Ready Mugs",
        url: "products/ready-made",
      },
      {
        name: "Customize mugs",
        url: "products/customize-mugs",
      },
      {
        name: "Rim Mugs",
        url: "/products/rim-mugs",
      },
      {
        name: "Silicon covers",
        url: "/products/silicon-covers",
      },
      {
        name: "Hard covers",
        url: "/products/hard-covers",
      },
    ],
  },
  {
    title: "",
    links: [
      {
        name: "2D covers",
        url: "/products/2d-covers",
      },
      {
        name: "Water Port",
        url: "/products/water-port",
      },
      {
        name: "Wallet",
        url: "/products/wallet",
      },
      {
        name: "T-Shirt",
        url: "/products/t-shirt",
      },
      {
        name: "Magic Mirror",
        url: "/products/magic-mirror",
      },
      {
        name: "Frame",
        url: "/products/frame",
      },
    ],
  },
];

const currentYear = new Date().getFullYear();
const Footer = () => {
  return (
    <footer className="relative w-full md:text-lg text-sm border-t border-blue-gray-50 pt-10 bg-[#424242]">
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="mx-auto grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5">
          {SITEMAP.map(({ title, links }, key) => (
            <div key={key} className="lg:w-56">
              <Typography
                variant="small"
                className="my-3 md:mb-5 font-semibold capitalize md:text-lg text-sm text-white/80"
              >
                {title}
              </Typography>
              <ul>
                {links.map((link, key) => (
                  <Typography
                    key={key}
                    as="li"
                    color="blue-gray"
                    className="font-normal text-white md:text-lg text-sm"
                  >
                    <Link
                      to={link?.url}
                      className="inline-block pr-2 transition-transform hover:scale-105"
                    >
                      {link?.name}
                    </Link>
                  </Typography>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between text-white">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-white md:mb-0"
          >
            &copy; {currentYear}{" "}
            <a
              href="https://www.facebook.com/customize.shopper/"
              target="_blank"
              rel="noreferrer"
            >
              Customize Shopper BD
            </a>
            . All Rights Reserved.
          </Typography>
          <div className="flex gap-4 text-white sm:justify-center">
            <Typography
              as="a"
              href="https://www.facebook.com/customize.shopper/"
              target="_blank"
              rel="noreferrer"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
            <Typography
              as="a"
              href="/"
              target="_blank"
              rel="noreferrer"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </Typography>
            <Typography
              as="a"
              href="/"
              target="_blank"
              rel="noreferrer"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </Typography>
          </div>
          <a
            href="https://wa.me/+8801830018193/?text=Hello A SI F!"
            target="_blank"
            rel="noreferrer"
          >
            Development 💻
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
