import { lazy } from "react";
import Home from "../Pages/Home";

const ProductsTab = lazy(() => import("../Utils/ProductsTab"));
const PaymentSuccess = lazy(() => import("../Components/order/PaymentSuccess"));
const PaymentFailed = lazy(() => import("../Components/order/PaymentFailed"));
const TramsCondition = lazy(() =>
  import("../Components/CompanyPolicy/TramsCondition")
);
const PrivacyPolicy = lazy(() =>
  import("../Components/CompanyPolicy/PrivacyPolicy")
);
const ReplacementPolicy = lazy(() =>
  import("../Components/CompanyPolicy/ReplacementPolicy")
);
const ShippingPolicy = lazy(() =>
  import("../Components/CompanyPolicy/ShippingPolicy")
);
const RefundReturnPolicy = lazy(() =>
  import("../Components/CompanyPolicy/RefundReturnPolicy")
);

const Contact = lazy(() => import("../Pages/Contact"));
const FAQ = lazy(() => import("../Pages/FAQ"));
const ConfirmOrder = lazy(() => import("../Components/order/ConfirmOrder"));
const AddOrderDetails = lazy(() =>
  import("../Components/order/AddOrderDetails")
);
const ProductDetails = lazy(() =>
  import("../Components/Products/ProductDetails")
);
const PageNotFound = lazy(() => import("../Utils/PageNotFound"));

const PublicRoutes = [
  { path: "/", Component: Home },
  { path: "/products", Component: ProductsTab },
  { path: "/contact", Component: Contact },
  { path: "/faq", Component: FAQ },
  { path: "/products/:id", Component: ProductDetails },
  { path: "/products/:id/add-details", Component: AddOrderDetails },
  {
    path: "/products/:id/add-details/confirm-order",
    Component: ConfirmOrder,
  },
  { path: "/payment-fail", Component: PaymentFailed },
  { path: "/payment-success", Component: PaymentSuccess },
  // Company Policy
  { path: "/trams-condition", Component: TramsCondition },
  { path: "/privacy-policy", Component: PrivacyPolicy },
  { path: "/replacement-policy", Component: ReplacementPolicy },
  { path: "/refund-return", Component: RefundReturnPolicy },
  { path: "/shipping-delivery", Component: ShippingPolicy },

  { path: "*", Component: PageNotFound },
];
export default PublicRoutes;
