//TODO useEffect( use for scroll on page load bottom to top )

import React, { Suspense, lazy } from "react";
import Loader from "../Utils/Loader";
import whatsapp from "../Assets/images/customize-shopper-bd-whatsapp.png";
const Hero = lazy(() => import("../Components/Home/Hero"));
const Category = lazy(() => import("../Components/Home/Category"));
const Country = lazy(() => import("../Components/Home/Country"));
const Payment = lazy(() => import("../Components/Home/Payment"));
const FAQ = lazy(() => import("../Components/Home/FAQ"));
const About = lazy(() => import("../Components/Home/About"));

const Home = () => {
  return (
    <Suspense fallback={<Loader />}>
      <div>
        <Hero />
        <div className="fixed top-[88vh] z-50 right-5 w-16 h-16 animate-bounce">
          <a
            href="https://wa.me/+8801848189974/?text=Hello Customize Shopper BD!"
            target="_blank"
            rel="noreferrer"
          >
            <img className="rounded-full" src={whatsapp} alt="" />
          </a>
        </div>
        <div id="products">
          <Category />
        </div>
        <Country />
        <Payment />
        <div id="faq">
          <FAQ />
        </div>
        <About />
      </div>
    </Suspense>
  );
};

export default Home;
